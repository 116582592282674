import { makeApiRequest } from "@/src/lib/api-request";
import { useMutation } from "@tanstack/react-query"

interface reportVideoMutationParams {
    eventId? : string
    creatorId? : string
    description: string
}

export const useReportVideoMutation = () => {

    return useMutation({
        mutationKey : ['user','watchlist'],
        mutationFn : async (data : reportVideoMutationParams) => {
            const response = await makeApiRequest({
                url : '/User/addReportVideo',
                method : 'POST',
                data : 
                {
                    eventId : data.eventId,
                    creatorId : data.creatorId,
                    description : data.description
                },
                isAuthenticated : true,
                fallbackError : 'Failed to report video',
                isV3Api: true
            })    
        }
    });
}