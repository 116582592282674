'use client'
import './../../videojs/skins/party/videojs.css'
import videojs from 'video.js'
import '../../videojs/plugins/es/hlsjs.js'
import PlayerT from 'video.js/dist/types/player'
import { useEffect, useRef, useState } from 'react'
import { Box, Flex, Image } from '@chakra-ui/react'
import PremiumTag from '../video/PremiumTag'
import { useRouter } from 'next/navigation'
import { getDeviceType } from '@/src/lib/utils'

interface VidTypes {
    hlsLink: string
    thumbnailUrl: string
    title: string
    live?: string
    premium?: boolean
    videoDuration: string
    encryptVideoUrl?: string
}


export function VideoOnHover({ hlsLink, thumbnailUrl, title, live, premium, videoDuration, encryptVideoUrl }: VidTypes) {
    const videoRef = useRef<HTMLDivElement | null>(null)
    const playerRef = useRef<PlayerT | null>(null)
    const router = useRouter()
    let activePlayer: PlayerT | null = null // Global variable to keep track of the active player
    const [isHovered, setIsHovered] = useState<boolean>(false)
    const [started, setStarted] = useState<boolean>(false)
    const [isMobile, setIsMobile] = useState<boolean>(false)
    let isIphone = getDeviceType() === 'iPhone' ? true : false
    //@ts-ignore
    let isTouchTablet = typeof navigator !== 'undefined' ? ((/Macintosh/.test(navigator?.userAgent) && 'ontouchend' in document) || (window?.innerWidth > 767 && navigator?.maxTouchPoints > 0)) : false;
    // Check if the device is mobile
    // useEffect(() => {
    //     //@ts-ignore
    //     const mediaQuery = window.matchMedia('(max-width: 768px)')
    //     setIsMobile(mediaQuery.matches)

    //     const handleResize = () => {
    //         setIsMobile(mediaQuery.matches)
    //     }

    //     mediaQuery.addEventListener('change', handleResize)

    //     return () => {
    //         mediaQuery.removeEventListener('change', handleResize)
    //     }
    // }, [])
    // function hls360pLink(url: string) {
    //     const match = url.match(/\/(\d+)\.m3u8$/);
    //     if (match && parseInt(match[1], 10) > 4848) {
    //         return url.replace(/\/\d+\.m3u8$/, '/_360p.m3u8');
    //     }
    //     return url;
    // }
    // useEffect(() => {
    //     if (!playerRef.current && videoRef.current && hlsLink) {
    //         const videoElement = document.createElement('video')
    //         videoElement.className = 'video-js vjs-fluid'
    //         videoElement.id = encryptVideoUrl?.replaceAll("/", "") || ""
    //         videoElement.setAttribute('playsinline', 'true') // Ensures video plays inline on mobile
    //         videoElement.muted = true // Mute the video to comply with autoplay policies

    //         videoRef.current.appendChild(videoElement)

    //         const player = (playerRef.current = videojs(videoElement, {
    //             controls: false,
    //             autoplay: false, // Autoplay should still be false to comply with browser policies
    //             muted: true, // Muted to allow autoplay
    //             preload: 'none',
    //             sources: [
    //                 {
    //                     src: hls360pLink(hlsLink),
    //                     type: 'application/x-mpegURL',
    //                 },
    //             ],
    //         }))

    //         player.on('canplay', () => {
    //             setStarted(true)
    //         })

    //         // Error handling
    //         player.on('error', () => {
    //             console.error('Error playing the video:', player.error())
    //         })

    //     }

    //     return () => {
    //         if (playerRef.current && !playerRef.current.isDisposed()) {
    //             playerRef.current.dispose()
    //             playerRef.current = null
    //         }
    //     }
    // }, [hlsLink])

    // const handleMouseEnter = () => {
    //     setIsHovered(true)
    //     if (playerRef.current) {
    //         //@ts-ignore
    //         playerRef.current.play().catch((error) => {
    //             console.error('Error on play:', error)
    //         })
    //     }
    // }

    // const handleMouseLeave = () => {
    //     setIsHovered(false)
    //     if (playerRef.current) {
    //         playerRef.current.pause()
    //         playerRef.current.currentTime(0) // Reset video to the start
    //     }
    //     setStarted(false)
    // }

    function formatDuration(val: string): string {
        let seconds = Number(val)
        let hrs: number = Math.floor(seconds / 3600);
        let mins: number = Math.floor((seconds % 3600) / 60);
        let secs: number = Math.floor(seconds % 60);

        // Pad with leading zeros if needed
        const hrsStr: string = hrs < 10 ? '0' + hrs : hrs.toString();
        const minsStr: string = mins < 10 ? '0' + mins : mins.toString();
        const secsStr: string = secs < 10 ? '0' + secs : secs.toString();

        return `${hrsStr == "00" ? "" : hrsStr + ":"}${minsStr + ":"}${secsStr}`;
    }
    // useEffect(() => {
    //     if (isMobile && videoRef.current && hlsLink) {
    //         const observer = new IntersectionObserver(
    //             (entries) => {
    //                 entries.forEach((entry) => {
    //                     if (entry.isIntersecting) {
    //                         if (playerRef.current && activePlayer !== playerRef.current) {
    //                             // Pause the currently active video if it's different from the current one
    //                             if (activePlayer) {
    //                                 activePlayer.pause()
    //                                 activePlayer.currentTime(0)
    //                                 setIsHovered(false)
    //                             }

    //                             // Play the current video and set it as the active player
    //                             //@ts-ignore
    //                             playerRef.current.play().catch((error) => {
    //                                 console.error('Error playing the video:', error)
    //                             })
    //                             activePlayer = playerRef.current
    //                             setIsHovered(true)
    //                         }
    //                     } else {
    //                         if (playerRef.current && playerRef.current === activePlayer) {
    //                             setIsHovered(false)
    //                             // Pause the video if it is no longer visible and it was the active one
    //                             playerRef.current.pause()
    //                             playerRef.current.currentTime(0)
    //                             activePlayer = null
    //                         }
    //                     }
    //                 })
    //             },
    //             {
    //                 root: null,
    //                 rootMargin: '-20% 0px -30% 0px',
    //                 threshold: 0.9, // Trigger when 50% of the component is visible
    //             }
    //         )

    //         observer.observe(videoRef.current)

    //         return () => {
    //             if (videoRef.current) {
    //                 observer.unobserve(videoRef.current)
    //             }
    //             observer.disconnect()
    //         }
    //     }
    // }, [isMobile])
    return (<>
        <Box
            id="hoverPlayer"
            cursor="pointer"
            // onMouseEnter={handleMouseEnter}
            // onMouseLeave={handleMouseLeave}
            position="relative"
            width="100%"
            height="100%"
        >
            <Image
                position="absolute"
                top="0"
                left="0"
                width="100%"
                height="100%"
                objectFit="cover"
                transition="opacity 0.4s linear"
                src={thumbnailUrl}
                // style={{ opacity: (isIphone || isTouchTablet || !hlsLink || !isHovered || !started) ? 1 : 0 }}
                h="full"
                w="full"
                alt={title}
                aria-label={title}
                zIndex="0"
            />
            {/* {encryptVideoUrl && (isMobile || isTouchTablet) && */}
                <Box zIndex={1} background={'transparent'} position={'absolute'}
                    width={'100%'} height={'100%'} onClick={() => router.push(encryptVideoUrl || '')} />
                    {/* } */}
            {/* {!isIphone && !isTouchTablet &&  */}
            <Box
                ref={videoRef}
                width="100%"
                height="100%"
                style={{ opacity: isHovered && started && hlsLink ? 1 : 0 }}
                zIndex="-1"
                transition="opacity 0.4s linear"
            />
            {/* } */}
        </Box>
        {!live && (!hlsLink || !isHovered || !started) ?
            <Flex gap={2} position="absolute" bottom="10px" right="10px">
                {premium ? <PremiumTag /> : null}
                <Box
                    bg="black"
                    opacity={0.8}
                    color="white"
                    fontSize="sm"
                    fontWeight="semibold"
                    px={2.5}
                    py={1.2}
                    rounded="md"
                >
                    {formatDuration(videoDuration)}
                </Box>
            </Flex> : ''}
    </>
    )
}
